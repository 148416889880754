.main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.routeContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: auto;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}