.main {
  /* padding: 4px; */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
  border-bottom: 2px solid white;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  background-color: #efefef;
}

.topbar {
  height: 6px;
  background-image: linear-gradient(90deg, #aa418c, #ff9300);
}

.bottombar {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.left {
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
}

.right {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.title {
  padding-left: 12px;
  font-size: 1.2em;
  font-weight: 100;
}

.menu a{
  padding: 3px 6px;
  text-decoration: none;
  color: black;
}

.menu a:hover {
  color: #e07531;
}

.menu a.selected{
  color: #e07531;
  font-weight: bold;
}
