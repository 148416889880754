.main{
  padding: 12px;
  color: rgb(158, 158, 158)
}

.card {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 3px;
  box-shadow: 2px 2px 6px #afafaf;
  /* padding: 12px; */
  /* background-color: #ecdbe8; */
  background-color: #aa418c;
  /* color: white */
  color: #f2cde7
}
.interpretatie{
  padding: 3px 12px 9px;
  border-bottom: 1px solid white;
}
.bevoegdheidUittreksel{
  padding: 3px 12px 9px;
}

.cardHeader{
  font-size: 1.1em;
  font-weight: bold;
  color: white;
  padding: 6px 0 4px;
}