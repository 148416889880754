.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px 12px 48px;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  font-size: 1.1rem;
}

.main p{
  margin: 0.6em 0;
}

.intro{
  font-size: 1.2em;
  line-height: 1.4em;
  padding: 0 0 24px;
}

.optionContainer{
  display: flex;
  align-items: stretch;
}

.optionContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  padding: 12px;
  margin: 12px 12px 12px 0;
  width: 50%;
}

.optionText{
  padding: 12px 6px 24px
}