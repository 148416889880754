.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 12px 12px 48px;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    font-size: 1.1rem;
  }
  