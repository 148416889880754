.main {
  padding: 12px 12px 48px;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  font-size: 1.1rem;
}

.intro {
  font-size: 1.2em;
  line-height: 1.4em;
}